html {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  /* font-smoothing: antialiased; */
  -webkit-font-smoothing: antialiased;
}
html, body, #root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
body {
  font-family: 'Barlow', sans-serif;
}
.address-wrap {
  position: absolute;
  left: 120px;
  top: 2px;
  z-index: 9;
}
.mobileOnly {

}

.selectedCard {
  background-color: #dfdfdf;
}

.portal-hide-scrollbars {
  -ms-overflow-style: none;
}
.portal-hide-scrollbars::-webkit-scrollbar {
  width: 0;
}
.portal-flex {
  flex: 1 1 auto;
}
/* Elements Pages */
.portal-pages__header {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background: rgba(0,0,0,0.050);
}
.portal-pages__header-demo {
  max-width: 400px;
  margin-top: 0;
  margin-bottom: 150px;
  padding: 0px 48px;
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
}
.portal-pages__content-inner {
  margin-top: -200px;
  padding: 16px;
  box-shadow: 0px -40px 100px -50px rgba(0, 0, 0, 0.3), -20px 0px 40px -50px rgba(0, 0, 0, 0.2), 20px 0px 40px -50px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(20px);
  animation: portal-pages__content-inner .3s forwards 1 ease-in-out;
}
@keyframes portal-pages__content-inner {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
/* Custom style for Website */
.SiteLogo img {
  width: 250px;
  cursor: pointer;
}
.mob_Logo {
  background: #ffffff;
  text-align: center;
  display: block;
}
.addBg div{
  background: #ffffff !important;
}
@media screen and (max-width: 768px) {
  .csv-6 {
    margin-bottom: 35px !important;
  }
}
@media screen and (min-width: 992px) {
  .mobileMenu_cstm {
    display: none !important;
  }
  .LogoMainLeft, .LogoMainRght{
    width: 50%;
  }
}
@media screen and (max-width: 991px) {
  .webMenu_cstm {
    display: none !important;
  }
   .SiteNotify {
    text-align: center !important;
    margin-top: 15px;
  }
  .SiteLogo {
    text-align: center;
  }
  .LogoMainLeft, .LogoMainRght {
    width: 100% !important;
  }
}
.loginMain, .regMain, .forgetMain, .lockMain{
  width: 100%;
  height: 100%;
  background: #96e3fe;
}
.loginBtn span, .reg_btn span, .resetPassword span, .loginLock span{
  color: #ffffff !important;
}
.create_ac a, .alredy_reg a{
  background: #07a8f4 !important;
}
.create_ac a:hover, .alredy_reg a:hover {
  background-color: #0288d1 !important;
}
.colorSocial {
  color: #07a8f4;
}
.colorSocialTwter{
  padding: 0px 10px;
  color: #07a8f4;
}
/* Home page css */
.nwUser_green{
  background: #27a743;
}
.nwUser_blue{
  background: #017cfd;
}
.nwUser_orange{
  background: #fb6604;
}
.nwUser_yellow{
  background: #fac405;
}
.nwUser {
  text-align: center;
  padding: 36px 0px !important;
}
.nwUser p {
  margin: 0px;
  color: #ffffff;
  font-weight: 500;
  padding-bottom: 3px;
}
.nwUser label{
  font-weight: bold;
  color: #ffffff;
  font-size: 24px;
}
/* .cstm_wraper_full, .cstm_wraperInr3, .cstm_wraperInr4, .homeClick {
  height: 100%;
} */

.homeContent {
  height: 100%;
  margin: 0px 10%;
}
.cstm_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
}
.footerMinHeight {
  height: 66px;
}
.create_submit span {
  color: #ffffff;
}
.createUpUsr div {
  margin-left: 0px;
  margin-right: 0px;
}
.createUpUsr {
  margin: 22px auto 22px;
}
.veri_email p {
  margin-top: 0px;
  color: red;
}
.homeClick {
  margin: 35px auto 20px !important;
}
.emailVerifyHead h1 {
  font-size: 25px;
  margin-bottom: 0px !important;
  font-weight: 500 !important;
  margin-top: 0px;
}
.emailVerifyHead {
  text-align: center !important;
  margin: 52px auto 52px;
}
.emailVerify_input{
     margin: 0 auto;
}
.emailVerify_input div {
  margin-left: 0px;
  margin-right: 0px;
}
.describe_you {
  margin: 7% auto 0px;
}
.describe_you h1 {
  font-size: 25px;
  font-weight: 400;
}
.describe_you h2 {
  font-size: 22px;
  font-weight: 600;
  color: #07a8f4;
  margin-bottom: 8px;
}
.describe_you li a {
  margin: 0px;
  font-size: 19px;
  margin-bottom: 0px;
  color: #0ca7f4;
  cursor: pointer;
}
.describe_you li {
  margin-bottom: 12px;
}

.letStartUsr div {
  margin-left: 0px;
  margin-right: 0px;
}
.letStartUsr {
  margin: 0 auto 7%;
}
.type_select {
  width: 100%;
  margin-top: 16px !important;
}
h1.getStartedH1 {
  text-align: center;
  margin-top: 35px;
  font-weight: 500;
  margin-bottom: 0px !important;
  font-size: 25px;
}
.selectOne {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 10px;
}
.selectOne a {
  display: inline-block;
  width: 72px;
  max-height: 88px;
  color: #ffffff;
  margin: 0px 3px;
  font-size: 16px;
  padding: 26px 0px;
  border-radius: 42px;
  cursor: pointer;
}
a.select_NUNC {
  background: #27a743;
}
a.select_NUEC {
  background: #017cfd;
}
.select_EUEC {
  background: #fb6604;
}
a.select_EUNC {
  background: #fac405;
}

a.select_NUNC:hover {
  background: #1e8234;
}
a.select_NUEC:hover {
  background: #004ea0;
}
a.select_EUEC:hover {
  background: #bb4c02;
}
a.select_EUNC:hover {
  background: #cca003;
}

.addBgCstm div:first-child {
  background: #ffffff !important;
}
.followColors h1 {
  font-weight: 500;
  font-size: 25px;
}
.followColors {
  text-align: center;
  margin-top: 52px;
}

.address_verify h1{
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
}
.address_verify {
  margin: 52px auto 52px;
}
.address_verify label {
  font-size: 20px;
  font-weight: 600;
  color: #07a8f4;
  margin-bottom: 8px;
}
.address_verify p {
  margin: 0px;
}
.address_verify div {
  position: relative;
  margin-bottom: 25px;
  padding-left: 30px;
}
.address_verify p span{
  width: 17px;
  display: inline-block;
  height: 17px;
  background: red;
  border-radius: 3px;
  vertical-align: text-top;
  margin-top: 1px;
  margin-right: 2px;
  margin-left: 2px;
}
.address_verify ul {
  margin-bottom: 0px !important;
  margin-top: 5px;
}
.add_verify {
  color: green;
  vertical-align: bottom;
  margin-left: 11px;
  position: absolute;
  bottom: -3px;
}
.use_verfyAdd button {
  color: #ffffff;
  background: #07a8f4;
  margin-right: 23px;
}
.use_verfyAdd h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

.cmunity_verify {
  margin: 52px auto 52px;
}
.cmunity_verify h1 {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 40px;
}
.nameLicence label {
  font-size: 18px;
  font-weight: 600;
  color: #07a8f4;
  margin-bottom: 8px;
  width: 123px;
  display: inline-block;
}
.nameLicence span{
  font-size: 18px;
}
.cmunity_verfyAdd button {
    color: #ffffff;
    background: #07a8f4;
    margin-right: 23px;
}
.cmunity_note p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}
.cmunity_note span {
  color: red;
}
.nameLicence p {
  margin-bottom: 0px;
  margin-top: 4px;
  font-size: 18px;
}
.cmunity_note {
  margin: 35px 0px 18px;
}

.boss_liv, .boss_cntct {
  text-align: center;
  margin-bottom: 14px;
}
.boss_liv p {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 0px;
}

.boss_liv button span {
  font-size: 18px;
  color: #07a8f4;
}
.boss_livUpr {
  text-align: center;
  margin-top: 45px;
}
.boss_cntct h1 {
  font-weight: 500;
  color: #07a8f4;
  font-size: 25px;
  margin: 0px;
}
.boss_cntct h2 {
  font-weight: 500;
  color: #07a8f4;
  font-size: 22px;
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 20px !important;
}
.boss_cntct h1 span {
  color: black;
}
.allow_code p {
  font-weight: 500;
  color: red;
  font-size: 20px;
  margin: 16px 0px;
}

.send_chat {
  text-align: center;
  margin-top: 45px;
}
.sendCode h1 {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 24px;
  margin-top: 0px;
}
.sendCode h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 24px;
  margin-top: 0px;
}
.sendCode h2 span {
  color: red;
}
.sendCode button {
  background: #07a8f4;
}
.sendCode button span {
  color: #ffffff !important;
  font-weight: 800;
  text-transform: capitalize;
  font-size: 17px;
}
.sendCode button:hover {
  text-decoration: none;
  background-color: #049fe8 !important;
}
.startChat p {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 24px;
  margin-top: 0px;
}
.startChat button {
  background: #07a8f4;
}
.startChat button span {
  color: #ffffff !important;
  font-weight: 800;
  text-transform: capitalize;
  font-size: 17px;
}
.startChat button:hover {
  text-decoration: none;
  background-color: #049fe8 !important;
}
.and_or p {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  margin: 0px;
  color: green;
  font-style: italic;
}
.and_or {
  margin: 25px 0px;
}
.enterCode_input {
  margin:112px auto 0px;
}
.enterCode_input div {
  margin-left: 0px;
  margin-right: 0px;
}
.enterCode_submit span {
  color: #ffffff;
}
.enterCode_submit {
  margin-top: 25px !important;
}

h1.createCmpnyH1 {
  margin-top: 45px;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 25px;
}
.letStartCmpny div {
  margin-left: 0px;
  margin-right: 0px;
}
.letStartCmpny {
  margin: 0 auto 0%;
}
.CmpnyStep1, .CmpnyStep2 {
  margin-bottom: 18px;
}
.CmpnyStep1Lbl, .CmpnyStep2Lbl{
  color: green;
  font-weight: 600;
  font-size: 19px;
}
.confirm_section {
  text-align: center;
  margin-top: 45px;
}
.confirm_section h1{
  font-weight: 500;
  color: #07a8f4;
  font-size: 25px;
  margin: 0px;
  margin-bottom: 18px;
}
.confirm_section h1 span {
  color: black;
}
.confirm_section h2 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 0px;
}
.enterConfrm_submit span {
  color: #ffffff;
}
.enterConfrm_submit {
  margin-top: 25px !important;
  float: left;
}
.added_liv h1{
  margin-top: 55px;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 40px;
}
.added_livBtn span {
  color: #ffffff;
}
.added_livBtn {
  margin-top: 25px !important;
  margin-bottom: 95px !important;
}
.added_liv {
  text-align: center;
  margin-top: 35px;
}
.added_liv h1 span {
  color: #07a8f4;
}
.chatStartIcon {
  color: #07a8f4;
  position: fixed;
  bottom: 75px;
  right: 25px;
  font-size: 52px !important;
}
.cstm_wraper_full {
  position: relative;
}
.added_use h1{
  margin-top: 0px;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 0px;
}
.added_useBtn span {
  color: #ffffff;
}
.added_useBtn {
  margin-top: 0px !important;
  margin-bottom: 95px !important;
}
.added_use {
  text-align: center;
  margin-top: 52px;
}
.added_use h1 span {
  color: red;
}
.othr_option {
  margin: 30px 0px;
}
.othr_option a {
  display: block;
  color: #07a8f4;
  margin: 9px 0px;
  font-size: 21px;
  font-weight: 500;
  cursor: pointer;
}
.cstm_wraper_full main {
  background: #ffffff !important;
  background-color: #ffffff!important;
}
.SiteNotify svg {
  color: #000001;
}
.SiteNotify {
  text-align: right;
  padding: 2px 0px;
}
.LogoMain {
  padding: 10px 45px;
}
.alterEmail h1 {
  margin-top: 0px;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}
.altrNxtBtn span {
  color: #ffffff;
}
.alterEmailUpr{
  padding: 33px 0px 0px;
}
.alterEmail div{
  margin-left: 0px;
}
.alterEmail2 h2 {
  margin-top: 0px;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}
.alterEmail2 div{
  margin-left: 0px;
}
.cellInput {
  width: 100%;
}
.alterOR label {
  font-weight: 600;
  font-size: 16px;
  color: green;
  font-style: italic;
}
.alterOR {
  margin: 18px 0px;
}
.updateSeting {
  margin: 12px 0px 0px;
}
.updateSeting h3 {
  margin-top: 0px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
  color: #07a8f4;
}
.ntShr li {
  margin-top: 0px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
  color: red;
}
.ntShr ul {
  margin: 0px;
  padding-left: 23px;
}
.altrNxtBtn {
  margin: 14px 0px 26px !important;
}
.csvMain {
  margin: 52px 0px 52px;
  text-align: center;
}
.csvContent img {
  height: 53px;
}
.csvContentCmn label {
  color: green;
  font-size: 22px;
  font-weight: 600;
  display: block;
}
.csvContentCmn p {
  color: #07a8f4;
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
}
.setUpload_Line {
  width: 440px;
  height: 2px;
  background: #0ca7f4;
  margin: 31px auto;
  position: relative;
}
.setUpload_Line label {
  margin-bottom: 0px;
  color: #03a9f4;
  background: #ffffff;
  display: inline-block;
  position: absolute;
  bottom: -7px;
  width: 126px;
  left: 36%;
  right: 36%;
  font-weight: 500;
  font-size: 14px;
}
.VoucherLogo img {
  width: 100%;
}
.basicInfo_form{
  margin: 0 auto;
}
.basicInfo_form div {
  margin-left: 0px;
  margin-right: 0px;
}
.typeRadioRghtInr {
  flex-direction: row !important;
}
.typeRadioMain {
  width: 100% !important;
}
.typeRadioLeft legend {
  padding-top: 16px !important;
}
.typeRadioMain {
  margin-top: 22px !important;
}
.create_submit {
  margin-top: 20px !important;
}
.basicInfoLbl {
  font-size: 21px;
  font-weight: 600;
  /* font-style: italic; */
  color: #246197;
  position: absolute;
  top: -17px;
  left: 20px;
  background: #ffffff;
  padding-left: 12px;
  padding-right: 12px;
}
.userdata {
  margin-top: 50px;
}
.userBasics {
  border: 2px solid #0288d1;
  /* border: 2px solid #0488d0; */
  padding: 20px 33px 35px;
  border-radius: 15px;
  position: relative;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 6px 7px 0px rgba(27, 95, 147, 0.44);
  margin-bottom: 55px;
}
.VoucherLogo {
  text-align: right;
}
.VoucherLogo img {
  width: 96%;
}
.CountryDropdown {
  width: 100% !important;
  margin-top: 16px !important;
}
/* Thanks for Singing Up */
.signSuccess h2 {
  font-size: 20px;
  font-weight: 600;
  color: #07a8f4;
  margin-bottom: 40px;
  margin-top: 0px;
}
.signSuccess h1 {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 10px;
}
.signSuccess {
  text-align: center;
  margin-top: 50px;
}
.selLoc span {
  color: #ffffff;
}
.selLoc {
  width: 192px;
  margin: 0 auto;
  margin-bottom: 32px !important;
}
.multiLocUpr {
  position: relative;
  display: inline-block;
}
.multiLoc {
  position: absolute;
  top: -17px;
  right: -26px;
  cursor: pointer;
}
/* End of Thanks for Singing Up */

.comunitySuccess h1 {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 28px;
}
.comunitySuccess h2 {
  font-size: 18px;
  font-weight: 600;
  color: #07a8f4;
  margin-bottom: 40px;
  margin-top: 0px;
}
.comunitySuccess h2 a{
  color: #07a8f4 !important;
}
.comunitySuccess h2 a:hover {
  color: #0185c7 !important;
}
.comunitySuccess {
  text-align: center;
  margin-top: 50px;
}
#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.uploadIconImg{
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 12px;
}
.uploadIconImg img{
  display: inline-block;
  width: 114px;
  margin-top: 18px !important;
}
.uploadIconImg span {
  display: block;
}
.cmpnyMultiLine{
  width: 100%;
}

/* Add user */
.userdata_add {
  margin: 62px 0px;
  margin-right: 54px;
}
.userBasics_add {
  border: 2px solid #0488d0;
  padding: 20px 33px 35px;
  border-radius: 15px;
  position: relative;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 6px 7px 0px rgba(27, 95, 147, 0.44);
  margin-bottom: 55px;
  WORD-BREAK: break-all;
}
.create_submit_add {
  margin-top: 20px !important;
}
.basicInfoLbl_add {
  font-size: 21px;
  font-weight: 600;
  /* font-style: italic; */
  color: #246197;
  position: absolute;
  top: -17px;
  left: 20px;
  background: #ffffff;
  padding-left: 12px;
  padding-right: 12px;
}
.basicInfo_form_add {
  margin: 0 auto;
}
.basicInfo_form_add div {
  margin-left: 0px;
  margin-right: 0px;
}
.create_submit_add span {
  color: #ffffff;
}
.schoolLogo {
  padding: 7px 0px;
  text-align: center;
  border-radius: 8px;
  margin-top: 21px;
  border: 1px solid #949494;
  margin-left: 14px !important;
}
.schoolLogo label span {
  display: block;
  color: #2894d5;
  margin-bottom: 4px;
  font-size: 14px;
}
.schoolLogo img {
  width: 48px;
}
#upload-photo-school {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.userBasicsPic {
  border: 1px solid #949494;
  padding: 20px 0px;
  border-radius: 15px;
  position: relative;
  margin-top: 32px;
  text-align: center;
  display: block;
  float: left;
}
.uploadIconImgPic {
  cursor: pointer;
}
.uploadIconImgPic img {
  width: 60px;
}
#upload-photo-profile {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.uploadIconImgPic span {
  font-size: 14px;
  display: block;
  margin-bottom: 4px;
  color: #0488d0;
}
.addNewCertificate {
  vertical-align: top !important;
  margin-top: 20px !important;
  margin-left: 18px !important;
}
/* End of Add user */

@media screen and (max-width: 600px) {
  .incentiveSbmt {
    text-align: left !important;
   }
}
@media screen and (min-width: 600px) {
  .spcInputRght {
    padding-right: 12px;
  }
  .spcInputLft {
    padding-left: 12px;
  }
  .yearFound {
    margin-top: 22px !important;
  }
}
@media screen and (max-width: 992px) {
  .spcInputRghtType{
    padding-right: 0px !important;
  }
  .spcInputLftType{
    padding-left: 0px !important;
  }
  .uploadIconImgPic span, .schoolLogo label span {
    margin-bottom: 10px;
  }
  .selectByType ul {
    margin: 0px !important;
  }
}
.cnfrmtonUpr div {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.schoolLogo label {
  cursor: pointer;
}

span.colorSocial span, span.colorSocialTwter span {
  color: #07a8f4;
}
/* Blog css */
.blogcard svg, .blogcard button {
  display: none !important;
}
/* End of Blog css */

/* Connection Provider Tables Design */
.connect_tab {
  margin-top: 35px;
}
.connect_tab table tr {
  text-align: left;
  line-height: 30px;
}
.connect_tab table th {
  font-size: 15px;
}
.connect_tab li.react-tabs__tab.react-tabs__tab--selected {
    box-shadow: 0px -6px 12px -8px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
}
.connect_tab ul {
  margin-bottom: 24px;
}
.rowEditIcon svg:last-child {
  margin-right: 0px !important;
}
.rowEditIcon svg {
  cursor: pointer;
  color: #03a9f4;
  font-size: 18px;
  vertical-align: text-bottom;
  margin-right: 0px;
}
.rowEditIcon svg:hover {
  color: #027bb1;
}
.connect_tab table td {
  font-size: 15px;
}
@media (max-width:768px) and (min-width:640px) {
  .connect_tab table td {
    font-size: 12px !important;
   }
   .connect_tab table th {
    font-size: 14px !important;
  }
  .rowEditIcon svg {
    font-size: 15px !important;
    margin-right: 1px !important;
  }
}
.selectByType li {
  list-style-type: none;
  min-width: max-content;
}
.selectByType li span {
  height: 27px;
  font-size: 15px;
  display: inline-block;
  font-weight: 400;
}
.selectByType ul {
  padding-left: 27px;
  border: 1px solid #03a9f4;
  display: block;
  width: max-content;
  padding: 12px 25px;
  border-radius: 15px;
  overflow: auto;
  height: 150px;
}
.shareBy svg {
  cursor: pointer;
  color: #03a9f4;
  margin-right: 24px;
}
.shareBy svg:hover {
  color: #027bb1;
}
li.shareBy {
  margin-top: 14px;
}
.cmntyIncentive h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 10px;
}
.cmntyIncentive h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
}
.cmntyIncentive {
  margin: 20px 0px 20px;
}
.incentiveSbmt button {
  background-color: #03a9f4 !important;
  color: #ffffff;
}
.cmntyIncentive h4 span {
  color: #03a9f4;
  font-weight: 600;
  font-size: 19px;
}
.incentiveSbmt {
  margin: 21px 0px;
}
/* .connect_tabUper div {
  padding-left: 4px !important;
  padding-right: 4px !important;
} */
.visibleInfo svg{
  color: #03a9f4;
  cursor: pointer;
  font-size: 17px !important;
  margin-left: 2px;
  vertical-align: text-bottom;
  width: 17px !important;
}

.srchVolun {
  margin-top: 35px;
}
.srchVolun a {
  cursor: pointer;
}
.srchVolun a span {
  vertical-align: top;
  font-size: 22px;
  display: inline-block;
  margin-top: 5px;
}
.srchVolun svg {
  font-size: 37px;
  color: #07a8f4;
  margin-right: 12px;
}
.voluntryInnerTabs header {
  width: 48%;
  margin: 0 auto;
  background: #f0faff;
}
.setingMiles h1 {
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
}
.setingMiles h2 {
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
  margin-top: 18px;
  margin-bottom: 10px;
}
.setingMiles {
  margin-top: 32px;
}
.setingMiles h1 span {
  color: #07a8f4;
}
.allVoluntry p {
  font-size: 19px;
  font-weight: 400;
  margin: 0px;
  margin-top: 24px;
  margin-bottom: 10px;
  color: red;
}
.settingMilesOptions label span:first-child, .allVoluntry label span:first-child{
  height: 32px !important;
}
.allVoluntryNeed h3 {
  font-size: 19px;
  font-weight: 400;
  margin: 0px;
  margin-top: 24px;
  margin-bottom: 10px;
}
.allVoluntryNeed p a {
  color: #07a8f4;
  cursor: pointer;
}
.allVoluntryNeed {
  margin: 32px 0px !important;
}
.srchVolun button {
  background-color: #03a9f4 !important;
  color: #ffffff;
}
.srchVolun button span{
  color: #ffffff;
}
.setingMiles h4 {
  font-size: 19px;
  font-weight: 400;
  margin: 0px;
  margin-top: 24px;
  margin-bottom: 25px;
}
.setingMiles h4 button {
  background-color: red !important;
}
.setingMiles h4 button span{
  color: #ffffff;
}
.setingMiles h4 button {
  background-color: red !important;
  margin-left: 12px;
  border-radius: 8px;
  font-size: 11px;
  min-height: 26px !important;
}
.emailFormBtns {
  margin-top: 10px;
  text-align: right;
}
h1.emailConnection {
  font-size: 20px;
  margin-top: 32px;
  font-weight: 500;
  color: #03a9f4;
}
.emailFormBtns button {
  background-color: #03a9f4 !important;
  color: #ffffff;
  margin-left: 8px;
}
.emailContent .ql-editor {
  min-height: 110px;
}
/* End of Connection Provider Tables Design*/
.mob_Logo img {
  width: 124px;
}
.userBasicsPic button{
  position: relative;
}
.userBasicsPic button{
  position: absolute;
  right: 17px;
  top: 48px;
  width: 32px;
  height: 27px;
}
.hlpSrchLogo label {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 14px;
  display: block;
  color: #01adf0;
}
.hlpSrchLogo {
  text-align: center;
}
.addVolunteer {
  margin-right: 2%;
  margin-left: 2%;
}
/* header new section edits */
.login_as p {
  margin: 0px;
  font-weight: 500;
  font-size: 12px;
  color: #4b6d86;
}
.login_as a {
  color: #03a9f4 !important;
  border-right: 1px solid #626168;
  padding: 0px 6px;
  cursor: pointer;
}
.login_as span {
  color: #000000;
  font-weight: 600;
}
.login_as a:last-child {
  padding-right: 0px !important;
  border-right: none !important;
}
.userSetting a {
  font-weight: 500;
  font-size: 12px;
  vertical-align: super;
  border: 1px solid #03a9f4;
  padding: 3px 6px;
  border-radius: 4px;
  margin-right: 9px;
  cursor: pointer;
  display: inline-block;
  background: #03a9f4;
  color: #ffffff !important;
}
/* .userSetting a:hover {
  background: #03a9f4;
  color: #ffffff;
} */
.userSetting svg:hover {
  color: #03a9f4;
}

.userSetting svg {
  font-size: 23px;
  margin-right: 4px;
  cursor: pointer;
}
.userSetting svg:last-child {
  margin-right: 0px !important;
}
.setNearBy a:last-child {
  margin-right: 0px !important;
}
.userSetting {
  margin-top: 4px;
}
.setNearBy {
  margin-top: 8px;
}
.setNearBy a {
  color: #03a9f4 !important;
  font-weight: 500;
  font-size: 12px;
  vertical-align: super;
  border: 1px solid #03a9f4;
  padding: 3px 6px;
  border-radius: 4px;
  margin-right: 9px;
  cursor: pointer;
  display: inline-block;
}
.setNearBy a:hover {
  background: #03a9f4;
  color: #ffffff !important;
}
/* find mom community */
.cmnutyType_cntnt h1 {
  font-size: 25px;
  font-weight: 400;
  margin-top: 28px;
}
.cmnutyType_cntnt h2 {
  font-size: 19px;
  font-weight: 600;
}
.cmnutySelect_cntnt h3 {
  font-size: 22px;
  font-weight: 600;
  color: #07a8f4;
  margin-bottom: 8px;
}
.cmnutySelect_cntnt ul li a {
  margin: 0px;
  font-size: 18px;
  margin-bottom: 0px;
  color: #0ca7f4;
  cursor: pointer;
  text-decoration: underline;
}
.cmnutySelect_cntnt ul li {
  list-style-type: none;
  margin-bottom: 9px;
}
.cmnutySelect_cntnt ul {
  padding-left: 0px;
}
.cmntysubmitBtn span {
  color: #ffffff;
}
.selectActv {
  color: red !important;
}
.login_as a:hover {
  color: #000000 !important;
}
.srchSrcvLbl {
  font-weight: 500;
  font-size: 22px !important;
  color: #000000 !important;
  margin-top: 32px;
  margin-bottom: 16px;
}
.srchSrcvCntnt svg {
  color: #03a9f4;
}
.skpSelect {
  color: #ffffff !important;
  background-color: #0288d1 !important;
  margin-top: 20px !important;
  width: 32px;
}
@media screen and (max-width: 568px) {
.LogoMain {
   padding: 20px 15px !important;
 }
 .setNearBy a {
  margin: 0px 2px !important;
 }
}
.relSrcvLbl {
  font-weight: 500;
  font-size: 22px !important;
  color: #000000 !important;
  margin-top: 32px;
  margin-bottom: 16px;
}
.relSrcvCntnt svg {
  color: #03a9f4;
}
.relskpSelect {
  color: #ffffff !important;
  background-color: #0288d1 !important;
  margin-top: 20px !important;
  width: 32px;
}
/* Community After Login  */
.Dtaltab {
  margin-top: 22px;
  padding: 0px 15px;
}
/* .DtaltabUper div {
  padding-left: 0px !important;
  padding-right: 0px !important;
} */
.DtaltabInnerTabs header {
  /* width: 70%; */
  margin: 0 auto;
  background: #f0faff;
}
.detailType svg {
  color: #03a9f4;
}
.showScreen img {
  width: 100%;
}
.DtaltabRght {
  padding-left: 35px;
}
.addBanner img {
  width: 100%;
}
.sbmtNextBtn button {
  color: #ffffff;
  margin-top: 5px;
}
.disp_pub p {
  margin-top: -12px;
  color: red;
}
/* End of Community After Login*/

/* Event Tab css */
.EventtabInnerTabs header {
  /* width: 50%;
  margin: 0 auto; */
  background: #f0faff;
}
.EventTabForm div {
  width: 100%;
}
@media screen and (min-width: 600px){
.evntInputRght {
    padding-right: 12px !important;
  }
.evntInputLft {
    padding-left: 12px !important;
 }
}
.rptEvent div {
  padding-right: 0px !important;
}
/*End of Event Tab css*/

.updateExt_submit {
  margin-top: 20px !important;
}
.updateExt_submit span {
  color: #ffffff;
}
.updateExt_submit button {
  min-width: 202px;
}
.updateExt_submit {
  text-align: center;
}
.updateExtng label {
  font-size: 22px;
  font-weight: 500;
}
.updateExtng {
  text-align: center;
}
.updateExtng span {
  position: relative;
  display: block;
  margin: 15px 0px;
}
.rptEvent {
  margin-top: 16px !important;
}
.include_rsvp p {
  margin-top: 12px;
  color: red;
  margin-bottom: -5px;
  font-size: 17px;
  font-weight: 500;
}
.AddFewEvents{
  margin-left: -14px;
}
.updateRvp_submit {
  margin-top: 20px !important;
}
.updateRvp_submit span {
  color: #ffffff;
}
.updateRvp_submit button {
  min-width: 202px;
}
.updateRvp_submit {
  text-align: center;
}
.eventDocs {
  margin-top: 45px;
}
.eventDocs label {
  color: #0288d1;
  margin-bottom: 10px;
  display: block;
}
.uploadCal label {
  color: #0288d1;
  margin-bottom: 10px;
  display: block;
}
.uploadCal {
  margin-top: 38px;
}
.emailCountDown p {
  margin: 0px;
}
.emailCountDown p span {
  font-weight: 600;
}
.emailCountDown label {
  height: 28px;
}
.emailBlast p {
  font-weight: 600;
}
.eventPre {
  margin: 8px 15px;
  position: relative;
  padding: 20px 33px 20px;
  border: 2px solid #0488d0;
  border-radius: 15px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 6px 7px 0px rgba(27, 95, 147, 0.44);
}
.eventPre p {
  font-size: 21px;
  font-weight: 600;
  color: #246197;
  position: absolute;
  top: -33px;
  left: 20px;
  background: #ffffff;
  padding-left: 12px;
  padding-right: 12px;
}
.eventPre label {
  font-weight: 600;
  margin-right: 14px;
  min-width: 70px;
  display: inline-block;
}
.EventTabForm {
  margin-top: 30px;
}
.eventPre button {
  border: 1px solid #dfdfdf;
  margin-top: 16px;
}
.includeRsvp {
  margin-top: 15px;
  margin-bottom: 15px;
}

.yrEvnts_tabInner table tr {
  text-align: left;
}
.yrEvnts_tabInner table th {
  font-size: 14px;
  font-weight: 600;
}
.yrEvntsEdit svg {
  cursor: pointer;
  color: #03a9f4;
  font-size: 24px;
  margin-right: 5px;
}
.profiledetails {
  background: #03a9f452;
}
.nxtEmail p {
  margin: 0px;
}
.nxtEmail {
  margin-bottom: 20px;
}
.nxtEmail span {
  font-weight: 500;
  color: #03a9f4;
}
@media screen and (max-width: 414px){
.detailTabs{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
/* Messages tab content */
.msgNw_tab{
  margin-top: 35px;
  padding: 0px 15px;
}
.msgNw_tab table td{
  font-size: 15px;
}
.msgNw_tabInner table th{
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
/* Incentives Add tab design  */
.incentiveLbl {
  font-weight: 700;
  display: block;
  margin-bottom: 8px;
}
.startDuration div {
  width: 100%;
}
@media screen and (min-width: 600px){
.startTimeRght {
    padding-right: 12px !important;
 }
 .startTimeLeft {
  padding-left: 12px !important;
 }
}
.durationLmt {
  margin-top: 32px;
}
.durationLmtLft {
  padding-right: 12px;
}
.durationLmtRght {
  padding-left: 12px;
}
.incentiveLbl2{
  font-weight: 700;
  display: block;
  margin-bottom: 8px;
}
.startDuration2 div{
  width: 100%;
}
@media screen and (min-width: 600px){
.startTimeRght2{
    padding-right: 12px !important;
 }
 .startTimeLeft2{
  padding-left: 12px !important;
 }
}
.durationLmt2{
  margin-top: 25px;
}
.durationLmtLft2{
  padding-right: 12px;
}
.durationLmtRght2{
  padding-left: 12px;
}
.startTimeLeft2 label {
  margin-right: 0px !important;
}
.startTimeLeft2 {
  position: relative;
}
.exRate {
  position: absolute;
  font-size: 10px;
  left: 12px;
  bottom: -8px;
  color: black;
}
.startTimeLeft2 label span {
  font-size: 12px;
}
.durationLmt3{
  margin-top: 25px;
}
.durationLmtLft3{
  padding-right: 12px;
}
.durationLmtRght3{
  padding-left: 12px;
}
.startTimeLeft3 label{
  margin-right: 0px !important;
}
.startTimeLeft3{
  position: relative;
}
@media screen and (min-width: 600px){
  .startTimeRght3{
      padding-right: 12px !important;
   }
   .startTimeLeft3{
    padding-left: 12px !important;
   }
   .imgLibrary {
    padding-left: 15px;
  }
}
.startDuration3 div {
    width: 100%;
}
.imgLibrary p {
  color: #03a9f4;
  font-weight: 500;
  margin: 0px;
  font-size: 16px;
  line-height: 20px;
}
.imgLibrary {
  text-align: center;
}
.imgLibrary img {
  width: 100px;
  cursor: pointer;
}
.imgLibrary {
  text-align: center;
}
.addDescpForm{
  margin-top: 32px;
}
.addDescpForm button {
  color: #ffffff;
  margin-top: 5px;
}
.note_incentive label {
  color: #03a9f4;
}
.note_incentive p {
  font-weight: 500;
  margin: 0px;
  font-size: 16px;
}
.note_incentive {
  margin-top: 26px;
}

.uploadIconImgPic2 {
  cursor: pointer;
}
.uploadIconImgPic2 img {
  width: 60px;
}
#upload-photo-profile2 {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.imgLibraryInner {
  border: 1px solid #03a9f4;
  padding: 22px 12px;
  border-radius: 12px;
}
.durationLmtRghtNxt div {
  width: 100%;
  padding-right: 0px !important;
}
.editUpload svg {
  font-size: 19px;
}

.editUpload :hover {
  color: #07a8f4;
}
.editUpload {
  cursor: pointer;
  text-align: right;
  margin-top: 12px;
}
/* Incentives Add tab design  */

/* Incentive added */
.incent_added{
  margin-top: 40px;
  text-align: center;
  padding: 0px 10%;
}
.incent_added p {
  font-size: 24px;
  line-height: 28px;
  margin: 0px;
  font-weight: 400;
}
.jump_incenttive button {
  color: #ffffff;
  margin-bottom: 20px;
}
.jump_incenttive {
  margin-top: 54px;
}
/* my incentive tab */
.myIncentiveTabs header {
  margin: 0 auto;
  background: #f0faff;
}
.myIncentiveTabs div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.myIncentiveTabs table th {
  font-size: 15px;
  text-align: left;
}
.faqCollection h2 {
  font-weight: 600;
  margin-bottom: 21px;
}
.faqLabl p {
  font-size: 18px;
  font-weight: 500;
}
.faqCollection svg {
  color: #07a8f4;
}
.aboutCntnt {
  margin-top: 26px;
}
.incentiveTabsMain {
  margin-top: 0px;
}
.myIncentiveTabs tr th, .myIncentiveTabs tr {
  text-align: center;
}
/* Photos Tab Content */
.photoColection {
  padding: 0px 5px;
  margin-bottom: 5px;
  position: relative;
}
.photoColection img {
  /* width: 100%; */
  border-radius: 5px;
  height: initial;
  width: 100%;
  max-height: 278px;
}
.photoColectionImg {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex: 1;
}
.photoEdit {
  left: 5px;
  position: absolute;
  top: 0;
  background: #ffffff87;
  height: 25px;
  padding: 4px 3px;
  border-radius: 5px 0px 5px 0px;
}
.photoEdit svg {
  cursor: pointer;
  font-size: 15px;
}
.photoEdit svg:hover {
  color: #f54f4f;
}
.imgLibraryPic {
  border: 1px solid #03a9f4;
  padding: 36px 0px;
  border-radius: 12px;
  text-align: center;
}
#uploadImgPic {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.imgLibraryPic img {
  width: initial;
  width: 87px;
  cursor: pointer;
}
/* End of Photos Tab Content */

/* Profile Setting css */
.updt_emailPass{
  margin-top: 24px;
  font-weight: 500;
  margin-bottom: 0px !important;
  font-size: 20px;
}
.update_usr div {
  width: 100% !important;
}
.updateUsrTab {
  margin-top: 32px;
}
.update_usr p {
  font-size: 12px;
  margin: 0px;
  font-weight: 500;
  margin-top: -4px !important;
}
p.selectOr {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  padding-top: 21px;
}
.addClons1 {
  position: absolute;
  bottom: -5px;
  right: 0;
  cursor: pointer;
}
.addClons2 {
  position: absolute;
  bottom: -20px;
  right: 0;
  cursor: pointer;
}
.addClonsUpr {
  position: relative;
}
@media screen and (min-width: 600px){
  .updateInputRght {
      padding-right: 12px;
  }
  .updateInputLft {
    padding-left: 12px;
  }
}

.notShared {
  margin-top: 15px;
}
.notShared p {
  color: red;
}
.update_alert {
  margin-top: 24px;
}

/* Start Search Here  */
.mileZip2 {
  display: flex;
}
.distance_mile2 {
  margin-right: 1%;
  width: 49%;
}
.distance_zip2 {
  width: 49%;
  margin-left: 1%;
}
.distance_mile2 div, .distance_zip2 div{
  width: 100%;
}
.distance_zipInr2 {
  margin-top: 0px !important;
}

.mile_citystate2  {
  display: flex;
}
.distance_city2 {
  margin-right: 1%;
  width: 49%;
}
.distance_state2 {
  width: 49%;
  margin-left: 1%;
}
.distance_city2 div, .distance_state2 div{
  width: 100%;
}
.country_state2 div {
  width: 100%;
}
.BusinessNameInput2 div {
  width: 100%;
}
.connect_tabUper2 div {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.zipCitySrchUpr2 header {
  margin: 15px 0px 28px;
}
.mngLabel2 {
  margin-top: 0px;
}
.typeComunitySrch label {
  display: block;
  text-align: center;
  border: 2px solid #03a9f4;
  padding: 20px 6px;
  width: 222px;
  margin: 14px auto;
  border-radius: 38px;
  color: #03a9f4;
  outline: none;
  font-weight: 500;
  cursor: pointer;
}
.typeComunitySrch {
  margin-top: 30px;
}
.typeComunitySrch label:hover {
  background: #03a9f4;
  color: #ffffff;
  transition-delay: .1s;
  transition-duration: .7s;
}
.yr_srch h1 {
  font-size: 25px;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 8px;
}
.yr_srch h2 {
  font-size: 22px;
  color: #07a8f4;
  font-weight: 600;
  margin: 0px;
}
.yr_srch {
  margin-top: 32px;
}
/* End of Start Search here */

.saveSrch {
  text-align: center;
  margin: 32px 0px 52px;
}
.saveSrch h1 {
  font-weight: 500;
  font-size: 25px;
  margin: 0px;
}
.saveSrchBtn {
  text-align: center;
  margin-bottom: 14px;
}
.saveSrchBtn button {
  background: #dfdfdf;
  margin: 0px 12px;
  border: 1px solid #03a9f4;
}
.saveSrchField {
  width: 100%;
  margin-bottom: 18px !important;
}
.saveSrchBtn {
  margin: 0 auto;
}
.confirmSrch h1 {
  font-weight: 500;
  font-size: 25px;
  margin: 0px;
  margin-bottom: 12px;
}
.confirmSrch {
  text-align: center;
  margin: 35px 0px 24px;
}
.confirmSrchField {
  width: 100%;
  margin-bottom: 45px !important;
}
.confirmSrchBtn button {
  background: #dfdfdf;
  margin: 0px 12px;
  border: 1px solid #03a9f4;
}
.confirmSrchBtn {
  margin: 0 auto;
  text-align: center;
}
.confirmSrch p {
  margin: 0px;
  line-height: 22px;
}
.confirmSrchBtn {
  margin-top: 22px;
}
.saveSrchBtnInr {
  margin-top: 32px;
}
.createUpUsr h1 {
  font-size: 29px;
  font-weight: 500;
}
.createUpUsr h4 {
  font-size: 18px;
}
.prefer_method label {
  margin-bottom: 0px !important;
}
.chat_community a {
  display: block;
  padding: 4px 12px;
  margin-bottom: 3px;
  border: 1px solid #03a9f4;
  border-radius: 5px;
}

.chat_community p {
  display: block;
  padding: 4px 12px;
  margin-bottom: 3px;
  border-radius: 5px;
}

.volunteerTxt {
  font-weight: bold !important;
}

.chat_communityUpr {
  padding-left: 30px;
}
.chat_community span {
  vertical-align: middle;
  font-size: 13px;
  font-weight: 500;
}
.chat_community svg {
  vertical-align: middle;
  margin-right: 8px;
  color: #03a9f4;
}
.comEditsMain {
  margin-top: 35px;
}
.comEditsMain .carousel .control-arrow {
  opacity: 1 !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #005f8a !important;
}
.carousel .control-prev.control-arrow:before{
  border-right: 8px solid #005f8a !important;
}
.phoneDir p {
  margin: 0px;
  font-weight: 600;
  margin-bottom: 8px;
}
.phoneDir {
  margin-top: 40px;
}
.phoneDir b {
  font-weight: 500;
  color: #07a8f4;
}
.userLogo img {
  height: 158px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  padding: 12px 8px 0px;
}
.abcLogo img {
  width: 106px;
}
.abcLogo {
  margin-bottom: 15px;
}
.userDescp {
  text-align: center;
}
.userDescp label {
  font-size: 13px;
  font-weight: 600;
}
.userDescp span {
  font-size: 14px;
}
.addressInfoOne h3, .addressInfoTwo h3 {
  margin: 0px;
  font-weight: 500;
  margin-bottom: 6px;
  font-size: 19px;
}
.addressInfoOne p, .addressInfoTwo p {
  margin: 0px;
}
.addressInfoTwo label {
  margin: 0px;
  font-weight: 500;
  margin-bottom: 6px;
}
.addressInfoOne {
  margin-bottom: 32px;
}
.addressInfoTwo p {
  margin-bottom: 4px;
}
.aboutLiv p {
  margin: 0px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}
.aboutLiv h4 {
  padding: 8px 16px;
  margin: 0px;
  font-weight: 500;
  margin-bottom: 6px;
  position: absolute;
  top: 0;
  width: 100%;
  background: #dfdfdf;
  left: 0;
  right: 0;
  font-size: 15px;
}
.aboutLiv {
  border: 1px solid #dfdfdf;
  padding: 16px 22px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  padding-top: 47px;
}
.chat_community a:hover svg, .chat_community a:hover span {
  color: #ffffff;
}
.chat_community a:hover {
  background: #03a9f4;
}
.comunityMap img {
  width: 96%;
}
.communityLoc {
  margin-top: 32px;
}
.socialShare img {
  width: 25px;
  cursor: pointer;
  margin: 0px 5px;
}
.socialShare {
  text-align: center;
}
.providCom p {
  margin: 0px !important;
  font-size: 13px;
  color: #03a9f4;
}
.providCom {
  width: 100%;
  padding: 0px !important;
}
.providCom label {
  font-weight: 500;
  font-size: 13px;
}
.providComLbl div {
  margin: 6px 0px !important;
}
.providComLbl {
  background: #dfdfdf69 !important;
}
.providComMain {
  margin-left: 5%;
  padding-left: 30px;
  min-height: 100vh;
  height: 100%;
  border-left: 1px solid #dfdfdf;
}
.getOffers {
  text-align: center;
  margin-bottom: 40px;
}
.getOffers img {
  cursor: pointer;
  width: 210px;
}
.providCom img {
  width: 18px;
  vertical-align: text-bottom;
  margin-right: 10px;
}
.providComDetails {
  padding: 6px 10px !important;
}
.getOffersShr svg {
  margin: 8px 5px 0px;
  font-size: 31px;
  color: #07a8f4;
  cursor: pointer;
}
@media screen and (max-width: 768px){
.aboutLiv {
   margin-top: 32px;
 }
 .providComMain {
   margin-left: 0px !important;
   padding-left: 0px !important;
   border-left: none !important;
 }
 .socialShare {
   margin: 32px 0px;
 }
 .userDescp {
   margin: 32px 0px;
 }
}
.seniorLiving {
  margin-top: 15px;
}

.addPromoMain {
  display: flex;
}
.addLogo img {
  width: 58px;
}
.addPromoMain div {
  width: 33.3%;
}
.addCntnt {
  text-align: center;
}
.addCntnt label {
  font-weight: 500;
  font-size: 13px;
}
.addCntnt p {
  font-size: 9px;
  margin: 0px;
}
.addOffer {
  text-align: right;
}
.addPromoMain {
  display: flex;
  width: 100%;
}
.addOffer b {
  font-size: 12px;
}
.addOffer span {
  font-size: 11px !important;
  font-weight: 500;
}
span.offrTool {
  display: inline-block !important;
  max-width: 200px;
}
/* Payment methods */
.payMethod{
  margin-top: 45px;
}
.payMethod div{
  margin-bottom: 13px;
}
.payMethod svg{
  color: #03a9f4;
}
.payMethod img{
  border-radius: 5px;
  border: 2px solid #000000;
  height: 72px;
}
.payMethod h1{
  font-size: 21px;
  margin-bottom: 32px;
  font-weight: 400;
}
.paySubmit button{
  border: 1px solid #03a9f4;
}
.paySubmit{
  margin-top: 32px;
}
.analytics_sec div {
  width: 94% !important;
  position: relative;
}
/* End of Payment methods */

/* graph codes */
.analytics_lbl p {
  font-size: 18px;
  font-weight: 500;
  padding-top: 24px;
  margin: 0px;
  color: #000000;
}
.analyticsApply {
  text-align: center;
}
.analyticsApply button {
  font-size: 14px;
  border: 1px solid #03a9f4;
}
.analyticsApply button span {
  color: #03a9f4;
}
.analytics_sec {
  margin-top: 32px;
}
.helpspan {
  position: absolute;
  bottom: -17px;
  font-weight: 600;
  font-size: 11px;
  left: 0;
}
.userSession{
  border: 1px solid #dfdfdf;
  padding: 22px 22px;
  margin-bottom: 12px;
}
.userSessionIner div {
  width: 100%;
}
.userSession p {
  line-height: 12px;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0px;
}
.site_performance {
  border: 1px solid #dfdfdf;
  padding: 8px 12px;
  margin-bottom: 12px;
}
.analytics_sec {
  border: 1px solid #dfdfdf;
  margin-bottom: 12px;
  padding: 18px 21px 40px;
}
.usrColor {
  background: #dfdfdf;
  width: 100%;
  min-height: 12px;
  margin: 5px 0px;
}
.usrColorInr {
  background: #e14d57;
  min-height: 12px;
  width: 64% !important;
}
.sesionColorInr{
  background: #71b37c;
  min-height: 12px;
  width: 64% !important;
}
.pageColorInr{
  background: #71b37c;
  min-height: 12px;
  width: 64% !important;
}
.userSessionIner {
  padding: 0px 42px;
}
.userSession h3 {
  margin-bottom: 30px;
  font-size: 19px;
}
.crntSesion label {
  margin: 0px;
}
.crntSesion {
  text-align: right;
}
.trgtSesion {
  text-align: right;
}
.trgtSesion span {
  color: #71b37c;
  font-weight: 600;
}
.createCodeH1 {
  text-align: center;
}
.createCodeH1 h1 {
  font-size: 16px;
}
.createCodeH1 h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 7px 0px 0px;
}
.createCodeH1 p {
  margin: 0px;
  font-size: 12px;
}
.createCodeFrom {
  border: 1px solid #03a9f4;
  padding: 18px 16px;
  margin: 12px 0px;
  border-radius: 15px;
}
.createCodeFrom h3 {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}
.createCodeFrom p {
  text-align: center;
  font-size: 12px;
  margin: 0px;
}
.emailCode span {
  line-height: 15px;
  font-size: 14px;
}
.emailCode label {
  margin: 0px !important;
}
.emailCode label span {
  width: initial !important;
  margin-right: 4px;
}
.createDeclare p {
  line-height: 15px;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0px;
}
.emailCode svg {
  color: #07a8f4;
}
.selectUser {
  margin-bottom: 32px !important;
}
.genrateCode {
  display: flex;
  margin: 14px 0px 4px !important;
}
.genrateCodeLft {
  text-align: left;
  width: 50%;
}
.genrateCodeRght {
  text-align: right;
  width: 50%;
}
.genrateCodeLft span {
  color: #000000;
  font-weight: 700;
}
.genrateCodeRght a {
  color: #07a8f4 !important;
  font-size: 12px;
  padding: 2px 8px;
  border: 1px solid #03a9f4;
  font-weight: 500;
  border-radius: 5px;
}
.genrateCodeRght a:hover {
  color: #ffffff !important;
  background: #07a8f4 !important;
}
.plsConfirm {
  text-align: center;
  margin-top: 40px;
}
.plsConfirm h1 {
  color: #03a9f4;
  font-size: 27px;
  font-weight: 500;
}
.plsConfirm p {
  font-size: 22px;
  margin: 30px 32px 42px;
}
.plsConfirm button span {
  color: #03a9f4;
  font-size: 14px;
}
.plsConfirm button {
  border: 1px solid #03a9f4;
}
.plsConfirm p label {
  font-weight: 600;
}
.demoUserTxt label {
  margin-bottom: 0px;
  font-weight: 600;
  margin-right: 12px;
}
.demoUserTxt p {
  margin: 0px;
}
.aboutdetailsImg img {
  width: 100%;
}
.aboutdetailsImg {
  min-height: 200px;
  position: relative;
  border: 1px solid #dfdfdf;
  padding: 20px 25px 0px;
  background: linear-gradient(to bottom, #000000 0%, #03a9f421 100%);
}
.demoUserMain {
  margin-left: 32px;
}
.aboutdetails {
  margin-top: 30px;
}
.demoUserTxt span {
  color: #03a9f4;
  font-weight: 600;
}
.demoUserCntnt {
  border: 1px solid #dfdfdf;
  padding: 12px 22px;
  margin-top: 17px;
  position: relative;
  padding-top: 42px;
}
.demoUserCntnt h2 {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  font-size: 18px;
  background: #07a8f4;
  padding: 7px 20px;
  color: #ffffff;
  font-weight: 600;
  right: 0px;
}
.demoUserCntnt p {
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}
.snrLiv h1 {
  font-size: 25px;
}
.snrLiv p a {
  display: block;
  color: #07a8f4 !important;
  text-decoration: underline !important;
  text-decoration-color: #03a9f4 !important;
  font-weight: 500;
  font-size: 17px;
}
.snrLiv h1 {
  font-size: 20px;
  color: #000000;
  margin-bottom: 4px;
}
.snrLiv {
  margin-top: 42px;
}
sub.seePrvdrs{
  text-decoration: underline !important;
  text-decoration-color: #03a9f4 !important;
  font-weight: 600;
  font-size: 9px;
  cursor: pointer;
}
.aboutdetailsImg a {
  position: absolute;
  top: 0;
  right: 0;
}
.aboutdetailsImg a {
  display: flex;
  padding: 1px 2px;
  display: flex;
  background: #dfdfdf;
}
.aboutdetailsImg svg {
  font-size: 14px;
  color: #000;
}
.aboutdetailsImg svg:hover {
  color: #026d9e;
}
.lineGraph {
  width: 99px;
  height: 15px;
}
.userSession td {
  position: relative;
}
.traficChngRed {
  vertical-align: middle;
  position: absolute;
  right: 41px;
  bottom: 2px;
  font-size: 20px !important;
  fill: red !important;
}
.traficChngGreen {
  vertical-align: middle;
  position: absolute;
  right: 41px;
  bottom: 2px;
  font-size: 20px !important;
  fill: green !important;
}
.site_performance h4 {
  font-size: 14px;
  margin: 0px;
  margin-bottom: 18px;
}
.score_avgLeft p label {
  font-weight: 600;
}
.score_avgLeft img {
  width: 90%;
}
.score_avgRght {
  text-align: right;
}
.score_avgRght p a {
  font-size: 11px;
}
.score_avgRght p span {
  display: inline-block;
  font-weight: 700;
  margin-bottom: 4px;
}
.score_avg {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 14px;
  margin-bottom: 14px;
}
/* End of Graph Component */

/* Community Edit slider Vertically */
.vertical_slids ul.thumbs.animated {
  margin: 0px !important;
}
.vertical_slids .carousel .slide {
  background: transparent !important;
}
.vertical_slids .carousel .slide img {
  min-height: 224px;
}
.vertical_slids .carousel .thumbs-wrapper {
  margin: 5px 22px;
}

.userfound h1 {
  color: #03a9f4;
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 30px;
}
.userfound {
  text-align: center;
  margin-top: 40px;
}
.userfound p {
  font-size: 22px;
  margin: 0px;
}
.userfound p label {
  font-weight: 600;
  margin-bottom: 0px;
}
.userfound button {
  border: 1px solid #03a9f4;
  margin-top: 42px;
}
.userfound button span {
  color: #03a9f4;
  font-size: 14px;
}

.userInvite h1 {
  color: #03a9f4;
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 30px;
}
.userInvite {
  text-align: center;
  margin-top: 40px;
}
.userInvite p {
  font-size: 22px;
  margin: 0px;
}
.userInvite p label {
  font-weight: 600;
  margin-bottom: 0px;
}
.userInvite button {
  border: 1px solid #03a9f4;
  margin-top: 42px;
}
.userInvite button span {
  color: #03a9f4;
  font-size: 14px;
}
.userInvite h2 {
  color: #555555;
}

.userfound {
  text-align: center;
  margin-top: 40px;
}
.userfound p {
  font-size: 22px;
  margin: 0px;
}
.userfound p label {
  font-weight: 600;
  margin-bottom: 0px;
}
.userfound button {
  border: 1px solid #03a9f4;
  margin-top: 42px;
}

.usercomplete {
  text-align: center;
  margin-top: 40px;
}
.usercomplete p {
  font-size: 22px;
  margin: 0px;
}
.usercomplete p label {
  font-weight: 600;
  margin-bottom: 0px;
}
.usercomplete button {
  border: 1px solid #03a9f4;
  margin-top: 42px;
}
.score_avg p, .score_avg p label {
  margin: 0px;
}
.score_avgRght p label {
  font-size: 11px;
  font-weight: 700;
}
.score_avgRght p svg {
  font-size: 11px;
  font-weight: 700;
  color: green;
}
.score_avgRght p {
  line-height: 14px;
}
.score_avg:last-child {
  border-bottom: none !important;
  margin: 0px !important;
  padding: 0px !important;
}

.dvc_Type {
  border: 1px solid #dfdfdf;
  padding: 8px 12px;
  margin-bottom: 12px;
}
.dvc_Type h4 {
  font-size: 14px;
  margin: 0px;
  margin-bottom: 18px;
}

.desktop_bg {
  background: #ec932f;
  width: 100%;
  min-height: 26px;
  margin-top: 8px;
}
.desktop_mobile {
  background: #71b37c;
  width: 80%;
  min-height: 26px;
}
.tablet_bg {
  background: #5290e9;
  width: 60%;
  min-height: 26px;
}
.dvcCmn label {
  font-weight: 600;
  margin-bottom: 0px;
}
.dvcCmn p {
  font-weight: 600;
  font-size: 15px;
  margin: 0px;
  line-height: 16px;
}
.dvcCmn span {
  font-size: 11px;
}
.deviceDesktop p, .deviceDesktop span {
  color: #5290e9;
}
.deviceMobile p, .deviceMobile span {
  color: #71b37c;
}
.deviceTablet p, .deviceTablet span {
  color: #ec932f;
}
.site_performanceUpr {
  margin-left: 12px;
}

.session_Rgn {
  border: 1px solid #dfdfdf;
  padding: 8px 12px;
  margin-bottom: 12px;
}
.session_Rgn h4 {
  font-size: 14px;
  margin: 0px;
  margin-bottom: 18px;
}
.session_Rgn th, .session_Rgn td{
  font-size: 11px;
}
.addposition label {
  top: -7px;
}
.addposition {
  margin: 25px 0px 42px !important;
}
.addposition div {
  width: 98%;
}
.addposition button span {
  color: #ffffff;
}
.addposition button {
  margin-top: 12px;
  margin-bottom: 10px;
}

.addposition ul {
  display: block !important;
  margin: 17px 0px;
}
.addposition li {
  display: block !important;
  padding-left: 20px !important;
}
.addposition li a {
  font-weight: 500;
}
.addposition li a:before {
  content: '';
  width: 8px;
  height: 8px;
  background:#03a9f4;
  position: absolute;
  left: 0;
  bottom: 8px;
  border-radius: 38px;
}
.prefer_method svg {
  color: #07a8f4;
}
@media screen and (max-width: 768px){
  .userSessionIner {
    padding: 0px !important;
    margin-bottom: 21px;
  }
  .responsiveTable td {
    font-size: 12px !important;
  }
 .analyticsApply button {
    margin-top: 35px;
  }
  .analytics_sec {
    padding: 15px 6px 15px !important;
  }
  .site_performanceUpr {
    margin-left: 0px !important;
  }
  .userSession {
    padding: 12px 10px !important;
  }
  .analytics_sec div {
    width: 100% !important;
  }
  .score_avgRght {
    text-align: left !important;
    margin-top: 12px !important;
  }
  .session_Rgn table {
    margin-bottom: 14px;
  }
  .analytics_lbl p {
    padding-top: 0px !important;
  }
}

/* incentives page css */
.incentives_tabs {
  margin-top: 35px;
  padding: 0px 15px;
}

.incentiveTabForm {
  margin-top: 30px;
}
.incentiveTabForm div {
  width: 100%;
}
.incent_lbl label {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 0px !important;
}
.incentiveTabAmt div:first-child {
  width: initial;
}
.autoCalculate span {
  font-size: 13px;
  font-weight: 500;
}
.autoCalculate {
  margin-bottom: 0px !important;
}
.incentiveTabForm label {
  margin-bottom: 0px !important;
}
.crntNxt button:last-child {
  margin-right: 0px !important;
}
.crntNxt button {
  margin-right: 12px;
}
.crntNxt span {
  color: #ffffff;
}
.crntNxt {
  margin-top: 32px;
}
.editEncentivesLogo {
  border-left: 1px solid #dfdfdf;
  padding-left: 12px;
  margin-left: 12px;
  min-height: 100vh;
  height: 100%;
}
.editEncentivesInr svg {
  cursor: pointer;
}
.editEncentivesInr {
  text-align: center;
  margin-top: 18px;
}
.incentives_tabsLeft, .editEncentivesLogo {
  padding-top: 32px;
}
.sndTomail p {
  margin-bottom: 0px;
  font-weight: 600;
}
.sndTomail span {
  height: 23px !important;
}
.incentiveNote {
  margin-top: 25px;
  margin-bottom: 22px;
}
@media screen and (min-width: 600px){
.incentiveInputRght {
    padding-right: 12px !important;
  }
.incentiveInputLft {
    padding-left: 12px !important;
 }
}
/* search current Incentives */
.searchKey div {
  width: 96%;
  text-align: center;
}
.searchKey {
  margin-bottom: 12px;
}
.comnDropClas {
  padding-right: 21px !important;
}
.viewComnty h1 {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 15px;
}
.viewComnty{
  margin-top: 35px;
  margin-bottom: 40px;
}
.incen_data table tr {
  text-align: left;
}
.incen_data table th {
  font-size: 14px;
  font-weight: 600;
}
.incentAll span {
  color: red;
}
.submitAll span {
  color: #fff;
}
.submitAll button {
  margin-top: 21px;
}

/* CSV updates pages */
.adCom_livUpr {
  text-align: center;
  margin-top: 45px;
}
.adCom_liv, .adCom_cntct {
  text-align: center;
  margin-bottom: 14px;
}
.adCom_cntct h1 {
  font-weight: 500;
  color: #07a8f4;
  font-size: 25px;
  margin: 0px;
}
.adCom_liv p {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 0px;
}
.adCom_liv button span {
  font-size: 18px;
  color: #07a8f4;
}

h1.getComH1 {
  margin-top: 35px;
  font-weight: 500;
  margin-bottom: 0px !important;
  font-size: 25px;
}
.letComUsr {
  margin: 0 auto 7%;
}
.letComUsr div {
  margin-left: 0px;
  margin-right: 0px;
}
.createCom {
  margin-top: 20px !important;
}
.createCom span {
  color: #ffffff;
}
.type_selectCom {
  width: 100%;
  margin-top: 16px !important;
}
@media screen and (min-width: 600px){
.comInputLft {
    padding-left: 12px;
 }
.comInputRght {
    padding-right: 12px;
 }
}
/* usps address verify */
.uspsAdd h1 {
  font-weight: 500;
  color: #07a8f4;
  font-size: 20px;
  margin: 0px;
  margin-bottom: 19px;
}
.uspsAdd {
  margin: 35px 0px;
}
.uspsAdd h2, .uspsAdd h3{
  font-weight: 600;
  font-size: 19px;
}
.verfyWrn {
  vertical-align: sub;
  margin-left: 12px;
  color: red;
}
.uspsAdd p {
  margin-bottom: 0px;
}
.uspsSrvc img {
  width: 72px;
}
.uspsSrvc div {
  width: 93%;
}
.usevefiryAdd h4 {
  font-weight: 500;
  font-size: 19px;
  color: #8ebf59;
  margin-bottom: 12px;
}
.uspsSrvc {
  margin: 20px 0px;
}
.usevefiryAdd button {
  margin-right: 12px;
  border: 1px solid #07a8f4;
}
.usevefiryAdd button:last-child {
  margin-right: 0px;
}
.usevefiryAdd button span {
  color: #07a8f4;
}
.uspsNote {
  margin-top: 28px;
}

.acm_livUpr {
  text-align: center;
  margin-top: 45px;
}
.acm_liv, .acm_cntct {
  text-align: center;
  margin-bottom: 14px;
}
.acm_cntct h1 {
  font-weight: 500;
  color: #07a8f4;
  font-size: 25px;
  margin: 0px;
}
.acm_cntct h2 {
  font-weight: 500;
  color: #07a8f4;
  font-size: 22px;
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 20px !important;
}
.acm_liv, .acm_cntct {
  text-align: center;
  margin-bottom: 14px;
}
.acm_liv p {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 0px;
}
.acm_liv button span {
  font-size: 18px;
  color: #07a8f4;
}
.allowAcm p {
  font-weight: 500;
  color: red;
  font-size: 20px;
  margin: 16px 0px;
}
@media screen and (max-width: 768px){
.uspsSrvc div {
   width: 100% !important;
 }
}
/* new issues */
.login_blue_box .navigator-content div {
  margin-bottom: 11px;
}
.navigator-title h4 {
  margin-bottom: 18px;
}
.login_blue_box .navigator-content-a {
  text-decoration: underline;
}
.navigator-content-a h4 {
  font-size: 21px;
}

.looking_for {
  margin-top: 70px;
  text-align: center;
}
.looking_for button {
  margin: 34px 20px !important;
  display: inline-block;
}

.selectCommType .navigator-content div {
  margin-bottom: 11px;
}
.selectCommType .navigator-title h4 {
  margin-bottom: 18px;
}
.selectCommType .navigator-content-a {
  text-decoration: underline;
}

.cmnutySelect_cntnt ul li a {
  text-decoration: underline !important;
}
.cmnutyType_cntnt h1 {
  margin-bottom: 20px;
}
.cmnutyType_cntnt h2 {
  margin-bottom: 12px;
}
.cmnutySelect_cntnt h3 {
  margin-bottom: 15px !important;
  margin-top: 30px;
}
.basicInfo_form .image_size {
  margin-left: 5px;
  margin-right: 3px;
}
.login_blue_box {
  color: #03a9f4;
}
.cmunityType_cntnt h1{
  margin-top: 32px
}
/* End of new issues */
/*------------navdeep------------*/


/* .SearchResultDiv  #preview_modal_content{
  background-color: #d4fad4 !important;
}

.payment-dialog-box{
  background-color: #49c249;
} */

/* .volunteerArea {
  margin-top: 0px !important;
} */

.margiLeft {
  margin-left: 20px;
}

.addDobClass{
  position: absolute !important;
  border: none;
}

.addDobClass div{
  border: none;
}

.alignCentre{
  text-align: center;
}

.savesearchesCheckbox {
  margin-left: -50px;
}

.savesearchesLeftTab{
  margin-right: inherit !important;
}

.savesearchesRightTab{
  margin-right: inherit !important;
}

.cstmDoneSelect {
  font-size: x-large !important;
}

.cstmDoneSelectsub {
  font-style: italic;
  font-size: 20px;
}

.crossiconadduser {
  color: red;
  cursor: pointer;
}

.unselectedUsers {
  color: grey;
  cursor: pointer;
}

.multiple_email_message {
  color: red;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 5%;
}

.see_instruction {
  margin-top: auto !important;
  color: #03a9f4 !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}

.reset_code_div {
  text-align: center !important;
  margin: auto !important;
  margin-top: 5% !important;
}

.cstFamilyType {
  color: rgba(0, 0, 0, 0.87);
    display: inline-flex;
    position: relative;
    font-size: 1rem;
    font-family: Barlow;
    line-height: 1.1875em;
    align-self: baseline;
}

.userCodeShare{
  margin-left: 25px !important;
  margin-top: 5px !important;
  margin-bottom: -5px !important;
}

.userCodeReset{
  text-decoration: underline;
    color: #07a8f4;
}

.savedShareIcon{
  font-size: 50px !important;
}

.doneBtnFamily{
  background: white;
  position: sticky;
  z-index: 9999;
  min-width: 878px;
  bottom: 0px;
}

.filterSuggestionLi {
  cursor:pointer;
}

.connectiontab_Horizontalbar_vol {
  margin-left: 33% !important;
  margin-bottom: -7% !important;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 18px;
  height: 18px;
  color: #fff;
  font-weight: 800;
  font-size: 11px;
  border: 1px solid #db0202;
  background: #db0202;
  border-radius: 50%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  top: 8px;
}

.DoneBtnListShare { background: white;  z-index: 9999;  bottom: 16px; }

.cardRadioButtons{
  margin-top: 11px !important;
  color: red !important;
  cursor: pointer !important;
}

.sponsorcommunityname{
  cursor: pointer;
  margin-top: 3%;
  margin-bottom: 2%;
  color: #07a8f4;
}
.firstCheckboxes{
  margin-right: 5px;
}

.firstCheckboxes input:checked  {
  background-color: #2196F3;
}

.managesponsorhead {
  margin-top: 5%;
}

.managesponsorpaydetail {
  margin-bottom: 10%;
}

.manageSponsorcommunity{
  margin-top: 3%;
  margin-bottom: 2%;
  color: #07a8f4;
}

.volunteer_area{
  margin-top: 5px !important;
}
.volunteerBckBtn{
  margin-right: 10px !important;
}

.profilefieldscolor{
  color: #01adf0;
  cursor: pointer;
}
.connectiontab_Horizontalbar {
  margin-left: 50% !important;
  margin-bottom: -12% !important;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 18px;
  height: 18px;
  color: #fff;
  font-weight: 800;
  font-size: 10px;
  border: 1px solid #db0202;
  background: #db0202;
  border-radius: 50%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  top: 8px;
}

.BackgroundNone{
  background:none !important;
}

.connectionName{
  color: #03a9f4 !important;
  cursor: pointer;
  border:none
}

.connectionIcons{
  display: inline-block;
  max-width: 20px !important;
  height: auto;
  margin-bottom: 5px;
}

.connectionProfileIcons{
  display: inline-block;
  max-width: 20px !important;
  height: auto;
  margin-bottom: -5px;
}

.notification_Horizontalbar {
  display: inline-flex;
  width: 18px;
  height: 18px;
  color: #fff;
  font-weight: 800;
  font-size: 10px;
  border: 1px solid #db0202;
  background: #db0202;
  border-radius: 50%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  top: 8px;
  margin-left: 10px;
}

.auto_calculate_text{
  margin-left: 5px;
  color: #03a9f4;
  width: 50px;
}

.selectedCommunity {
  background-color: #dfdfdf;
}

.maskCurrency{
    width: 97%;
    border-bottom: solid;
    border-color: #949494;
    border-width: thin;
}

div#menu-type ul {
  overflow-y: scroll;
  height: 475px;
  padding-bottom: 65px;
}
/* button.cstmDone {
  position: fixed;
  bottom: 21px;
  z-index: 9999;
} */
div#menu-type div {
  transform: initial !important;
}
button.cstmDone {
  margin: 12px 12px 14px 19px;
}
button.cstmDone:after {
  content: "Done";
  color: #ffffff;
  font-size: 14px;
}
.react-dropdown-select input {
  font-size: 16px;
  color: #5a5555;
  outline: none !important;
}
.react-dropdown-select {
  border: none !important;
  border-bottom: 1px solid #949494 !important;
  padding-left: 0px !important;
}
.react-dropdown-select:focus-within {
  outline: 0;
  box-shadow: none !important;
}
.react-dropdown-select-dropdown {
  padding: 6px 11px !important;
}
.cstmMulti{
  width: 100% !important;
  max-width: 100% !important;
}
.list-group{
  max-height: 151px;
   overflow-y: scroll;
}
.react-dropdown-select-dropdown button {
  border: 1px solid deepskyblue;
  background: #03a9f4 !important;
  color: #ffffff !important;
  border-radius: 3px;
  margin: 10px 10px 0;
  padding: 3px 13px;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}

.send_to_checkbox{
  min-width: 135px
}


.activeSearchTab {
  background: #03a9f4b8 !important;
  border: 1px solid #ffffff !important;
}
.astrict {
  color: red;
}
.altrNxtBtnskip span {
  color: #ffffff;
}
.altrNxtBtnskip {
  margin: 14px 10px 26px !important;
}
.add-create-incentives  svg {
  color: #03a9f4;
}

.blueColor {
  color: #03a9f4 !important;
}

.Successmsg  {
  padding-top: 10px;
  padding-bottom: 15px;
  text-align: center;
  font-size: larger;
  color: #07a8f4;
  font-weight: 500;
}
.blue {
  color: #03a9f4;
}

input#searchCity {
  vertical-align: bottom;
  width: 100%;
  border-bottom: 1px solid #949494;
  margin-top: 40px;
}

input#searchCity::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #949494
}
input#searchCity::-moz-placeholder { /* Firefox 19+ */
  color: #949494
}
input#searchCity:-ms-input-placeholder { /* IE 10+ */
  color: #949494
}
input#searchCity:-moz-placeholder { /* Firefox 18- */
  color: #949494
}

.err_message {
  padding-top: 10px;
  padding-bottom: 15px;
  text-align: center;
  font-size: larger;
  color: #ff0000;
  font-weight: 500;
}

.navigator-title {
  margin-top:5%;
}

.navigator-content-a:hover {
  color: red;
}

.navigator-content-a{
  width: fit-content;
}

.terms_and_condition_checkbox {
  margin-top: 10px;
}
.a_link {
  color: #6495ed;
}
.relation-div {
  background: #fbfbf8;
  margin-top: 5% !important;
  border: solid 2px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  border-color: #afabab;
  height: auto;
}
.user-selection-button {
  color: white !important;
  margin-top: 10% !important;
  margin-left: 25% !important;
}

/* new one */
.preDateLblMain, .rptUntlLblMain{
  margin-top: 16px !important;
}
.preDateLblMain .react-date-picker__wrapper, .rptUntlLblMain .react-date-picker__wrapper{
  border: none;
  border-bottom: 1px solid #949494 !important;
}
.preDateLblMain .preDateLbl, .rptUntlLblMain .rptUntlLbl{
  color: #b2b2b2;
  font-weight: normal;
  font-size: 13px;
}
.uploadIconImg:hover span{
  color: #03a9f4;
}
.uploadImgnW{
  position: relative;
}
.uploadDelet{
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.preview_modal_content .eventPre {
  border: none !important;
  box-shadow: initial !important;
}
.rmvOverflow {
    /* overflow-x: initial !important; */
    overflow-y: hidden !important;
}

.Dtaltab header {
  width: 99%;
  margin: 0 auto;
  margin-bottom: 10px !important;
}
.add-create-incentives, .photogallery {
  margin-top: 21px;
}
.val_incentive{
  width: 100%;
}
.rateVal_doler {
  width: 96%;
}
.durationLmt2 label {
  margin-bottom: 0px !important;
}
.modal_close_btnNew {
  position: absolute;
  top: 0;
  right: 0;
}
.moreInfodata b {
  display: inline-block;
  min-width: 185px;
}
.moreInfodata b, .Primarlylbl b {
  font-weight: 600;
}
.moreInfoBtns {
  margin-bottom: 6px;
}
.moreInfoBtns b {
  color: #286499;
}
.userBasicsCstm {
  margin-bottom: 16px;
}
.Preview_page .community_col h2 b {
  font-weight: 600;
  color: #316a9d !important;
}
.Preview_page h1 {
  margin-bottom: 0px !important;
  display: inline-block;
  text-align: center;
  font-weight: 300;
  border-bottom: 2px solid #dfdffd;
}
.Preview_page .community_col h2 {
  margin-bottom: 10px !important;
}

.common_checked {
  position: relative;
}
.common_checked span:first-child:before {
  content: '' !important;
  color: #ffffff;
  position: absolute;
  left: 1px;
  top: 15px;
  font-size: 9px;
}
.common_checked span span:first-child:before {
  content: '' !important;
  padding-left: 0px !important;
}
.common_checked span span span:first-child:before {
  content: '' !important;
  padding-left: 0px !important;
}
.common_checked span span span span:first-child:before {
  content: '' !important;
  padding-left: 0px !important;
}

.common_unchecked {
  position: relative;
}
.common_unchecked span:first-child:before {
  content: '' !important;
  color: #ffffff;
  position: absolute;
  right: 1px;
  top: 14px;
}
.common_unchecked span span:first-child:before {
  content: '' !important;
  padding-left: 0px !important;
}
.common_unchecked span span span:first-child:before {
  content: '' !important;
  padding-left: 0px !important;
}
.common_unchecked span span span span:first-child:before {
  content: '' !important;
  padding-left: 0px !important;
}
label.addCapIcon {
  margin-right: 3px;
}
.photoEditTxt input {
  background: #ffffffd6;
  width: 100%;
  padding-left: 8px;
  font-weight: 500;
}
.photoEditTxt {
  position: absolute;
  bottom: 10px;
  left: 6%;
  right: 6%;
  width: 100%;
}
.photoEditTxt button span {
  color: #ffffff;
}
.photoEditTxt button {
  background: #1681b1;
  padding: 5px 16px !important;
  min-height: 32px !important;
  border-radius: 0px !important;
  vertical-align: bottom;
  background: #1681b1 !important;
}
.getSrchData {
  margin: 0 auto !important;
}
.options_type_search::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
.options_type_search::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}
.options_type_search::-webkit-scrollbar-thumb
{
	background-color: #03a9f4a6;
}
.userBasics.providertpyes.providertpyesRmv {
  padding: 9px 0px !important;
}
.homeClick h1 b {
  font-weight: 500;
  color: #000000;
  font-size: 23px;
}
.detailsList .detailsLabel a {
  color: #03a9f4;
  font-weight: 400;
  cursor: pointer;
}
.detailsList ul label {
  margin-bottom: 0px;
  display: inline-block;
  min-width: 132px;
  font-size: 14px;
  color: #808080;
}
.detailsList ul {
  background: #f7f7f7;
  padding: 11px 19px;
  position: absolute;
  width: 306px;
  top: 26px;
  left: 0;
  border: 1px solid #9f9fa1;
  border-radius: 6px 25px 6px 25px;
  line-height: 19px;
  z-index: 1;
}
ul.detailsList {
  position: relative;
}
.detailsList ul span {
  font-size: 14px;
  color: #005c86;
}
li.detailsLabel ul {
  display: none;
}
li.detailsLabel:hover ul {
  display: block;
}
li.detailsLabel ul li {
  display: flex;
}
.process_image {
  cursor: pointer;
}

/*Add by Ankita*/
.zipCitySrch button span { font-size: 12px !important; }
.btn-style-one .txt { color: #fff; }
.image_size { width: 31px; height: auto; }
.margin20px { margin-left:20px!important; }
.err_message1 { padding-bottom: 10px; text-align: center; color: #ff0000; font-weight: 500; }
.create-pass-heading { text-align: center; padding: 10px; }
#content_terms { padding-top: 30px; }
div#content_terms ul li { padding-top: 8px; }
div#content_terms ul { padding-bottom: 20px; }
#content_terms #footer1 a { font-size:22px; }
#content_terms a { color: #07a8f4; }
#content_terms li span { font-weight: 700;}
#content_terms li{ list-style-type: circle; }
#content_terms ul{  margin-left:5%; }
.schoolLogo img { height: auto; width: 60px; }
.schoolLogo .preview_img img { height: auto; width: 240px; }
.userBasicsPic img { width: 31%; padding-left: 4px; padding-right: 4px; margin-bottom: 6px; }
.listing_margin { margin-top:9px!important; }
a.condition_link { color: #03a9f4 !important; }
.Eventimageview{ padding-top:10px; height:100px; width:auto; cursor:pointer; }
.AddevntLink { color: #03a9f4; cursor: pointer; display: inline-block; border: 1px solid #03a9f4;
  padding: 4px 9px; margin-top: 24px; border-radius: 7px; }
.SetwidthTab button { min-width: 70px; }
.SetwidthTab button span { font-size: 10px; }
.fullwidthtext { width:100% }
.spcInputLft div { width: 100%; }
.spcInputRght div { width: 100%;     }
.Successmsg h2 { font-size: 20px; font-weight: 600; color: #07a8f4; padding-bottom: 10px; text-align: center; }
.EventtabUper svg, .DtaltabCntnt svg { color: #03a9f4; }
.InsuranceTab textarea { overflow: auto !important; }
#event_image, #event_pdf, #monthly, #monthly_update { opacity: 0; position: absolute; z-index: -1; }
.incentive_select { border: 2px solid #fb3f04 !important; }
.backLink { padding-top: 10px; font-size:19px; font-weight:500; }
.backLink span { color: #fb3f04; text-decoration: underline; cursor: pointer; }
.open-detail { display: block; color: #03a9f4 !important; z-index: 9; opacity: initial;}
.EstimationRate { font-size: 20px; text-align: right; font-weight: 600;}
.userBasics svg { color: #03a9f4; }
.term_service_link { color: #07a8f4; font-size: 22px; line-height: 2.5; }
.senior_community2 svg { color: #03a9f4;}
.mail_sent h4 { margin-bottom: 21px;}
.mail_sent a { display: block; text-decoration: underline !important; margin-bottom: 5px; font-size: 19px; color: #07a8f4 !important; }
.mail_sent { text-align: center; margin: 42px 0px;}
.save_search svg { color:#03a9f4; }
.no_data{ padding:10px; font-weight: 500;}
.save_search svg { color:#03a9f4}
.select_types { margin-top: 6%; }
.general_phone input{ border-bottom: 1px solid #949494; }
.general_phone{ margin-top: 21px !important; margin-bottom: 0px !important;}
.general_fax input{ border-bottom: 1px solid #949494; }
.general_fax { margin-top: 43px !important; margin-bottom: 25px !important;}
.general_fax input::-webkit-input-placeholder { color: #c1c0c0; }
.general_fax input::-moz-placeholder { color: #c1c0c0;}
.general_fax input:-ms-input-placeholder { color: #c1c0c0;}
.general_fax input:-moz-placeholder { color: #c1c0c0;}
.general_phone input::-webkit-input-placeholder {color: #c1c0c0; }
.general_phone input::-moz-placeholder { color: #c1c0c0;}
.general_phone input:-ms-input-placeholder { color: #c1c0c0; }
.general_phone input:-moz-placeholder { color: #c1c0c0; }
table.innerTabCntnt { margin-top: 5px; border: 1px solid #48c0f7;  padding: 12px 12px;}
table.innerTabCntnt tr { text-align: center; }
.innerTabCntnt th { font-size: 11px !important;}
.savesearch_table{ display: table; width: 100%; border:1px solid  #666666; border-spacing:5px;/*cellspacing:poor IE support for  this*/}
.headRow{ display: table-row; }
.savesearch_table_tr{ display:table-row; width:100%;}
.savesearch_table_td{ float:left;/*fix for  buggy browsers*/ display:table-column; width:108px;}
.savesearch_table_th{ float:left;/*fix for  buggy browsers*/ display:table-column; width:110px; font-weight: 700;}
.underline_text{ text-decoration:underline; }
.preview_btn{ margin-right: 20px !important;}
.modal_close_btn{  min-height: 40px !important; padding: 0px !important; text-align:right; width: 100%!important; }
.Component-root-1167 { min-height: 40px !important;}
.preview_modal_content { padding: 0px 10px;}
.Preview_page .mb15{ margin-bottom:15px; }
.Preview_page .pt10{ margin-top:10px !important; }
.Preview_page .mt40{ margin-top:40px; }
.Preview_page .mt10{ margin-top:10px; }
.Preview_page .mb20{ margin-bottom:20px !important; }
.Preview_page .community_col h2{ margin: 0; padding: 0; font-size: 18px; color: #316a9d !important; cursor: pointer; }
.Preview_page .community_col h2 a,.Preview_page  .community_col h2 a:hover{ color:#555555; }
.Preview_page .community_display_ul{ margin: 0; padding: 0; display: inline-block; width: 225px;}
.Preview_page .community_display_ul li { list-style-type: none; margin: 0; padding: 0; padding-top: 4px; }
.Preview_page .community_display_ul li a, .Preview_page .community_display_ul li a{ line-height: 30px; display: block; text-decoration: none; border:1px solid #3093ff; color: red; position: relative; padding-left: 55px; height: 30px; padding-right: 15px; }
.Preview_page .community_display_ul li a img{ max-width: 50px; }
.Preview_page .valign-mid{ vertical-align: middle !important; }
.Preview_page .valign-top{ vertical-align: top !important; }
.Preview_page .community_display_ul li a i{ position: absolute; top: 4px; bottom: 0; margin: auto; left: 10px; text-align: center; }
.Preview_page .red-border-btn2, .Preview_page .red-border-btn2:hover{ color:#fff; border-radius:20px; text-transform: none; font-size: 20px; font-weight: 600; background-color: #dc3545; width:220px; }
.Preview_page .red-border-btn2 span,  .Preview_page .red-border-btn2:hover span{ color:#fff; }
.modal_overview { word-wrap: break-word; }
.set_edit { padding-top:5px; padding-left:5% ; cursor: pointer;}
.filled_insurance span { color: #03a9f4; }
.eventDocs span, .uploadCal span { display: block; }
.uploadIconImg:hover, .eventDocs label:hover, .uploadCal label:hover { cursor: pointer; color: #03a9f4; }
.grid_display {display: table-caption;}
/* .photoColection img { height: 200px; } */
/* .defaulupload img {height: auto;} */
.PhotoGalleryupdate { opacity: 0; position: absolute; z-index: -1; }
.p_insurance_tab a { display: block; min-height: 44px; }
.preview-event-image { text-align: center; margin: 14px 0px; }
.event_preview_detial { display: flex;}
span.preEventSvg { margin-top: 16px; display: flex; }
span.preEventSvg svg { font-size: 19px; }
.company_logo_manage{ justify-content: center; }
.editevent_link { color: #03a9f4; cursor:pointer; }
.is_public_success {color: #03a9f4 !important;}
.preDateLbl { color: #c5c5c5; }
.rc-time-picker-input { width: 100% !important; border-radius: 0%!important;
  border: none !important; border-bottom: 1px solid #949494 !important;
  color: #434343 !important; font-size: 15px !important; }
.PDFviewer { border: 1px solid #3333; height: 265px!important; }
.PDFviewer .react-pdf__Page__annotations.annotationLayer { height: 0px!important; }
li.DoneBtnList { background: white; position: fixed; z-index: 9999; min-width: 878px; bottom: 0px;}
td.yrEvntsEdit.pivoted svg { font-size: 18px; }
.photo_caption { left: 6%; right: 6%; position: absolute; bottom: 10px; text-align: center; padding: 5px 15px; font-weight: 700; background: #ffffffd6;}
.photogallery{ padding: 0px 21px; }
.sharebtn{ color:#fff !important;}
.shareText{ width:100%;}
.ProfilePointer{cursor: pointer;}
.DoneBtnListComm { background: white; position: fixed; z-index: 9999; min-width: 878px; bottom: 16px; }
div#menu-sub_type ul {
  height: 284px; overflow-y: scroll;
  padding-bottom: 68px !important;
}

@-moz-document url-prefix() {
  div#menu-sub_type ul {
    margin-bottom: 56px !important;
  }
}

.DoneBtnListProv { background: white; position: fixed; z-index: 9999; min-width: 878px; bottom: 0px; }
.certificate_profile svg { right: 0; position: absolute; font-size: 16px; bottom: 0; color: #fff; background: #01adf0; cursor: pointer; }
.certificate_profile img{ width: 100% !important; padding-left: 0px !important; padding-right: 0px !important; margin-bottom: 0px !important;}
.certificate_profile { position: relative; width: 150px !important; margin-left: 4px !important; margin-right: 4px !important;
  margin-bottom: 6px; float: left; border: 1px solid #03a9f4; }
.SearchPre {  margin: 8px 15px; position: relative; padding: 20px 33px 20px;}
.SearchPre button {  border: 1px solid #dfdfdf; margin-top: 16px;}
.SearchPre p {  font-size: 21px; font-weight: 600; color: #246197; position: absolute; top: -33px;
  left: 20px; background: #ffffff;  padding-left: 12px; padding-right: 12px; }
.SearchPre svg {  color: #03a9f4; }
.options_type_search { height: 400px; overflow-y: scroll; padding: 20px 33px 35px; }
.providertpyesRmv{ padding: 0px !important; }
.basicInfoLbl{z-index:9;}
.Editevent_icon svg { cursor: pointer; font-size:18px; }
.update_alert svg { color: #03a9f4; }
.message_full_data { line-height: 2; margin-top: -1%; font-size: 15px; }
.select_types button { color: #fff !important;}
.include_secondary_text label span { font-size: 18px; }
.newbuttonMessage {color: #fff; background: #03a9f4 !important; padding: 3px!important; font-size: 11px; border-radius: 5px; }
.newbuttonMessage:hover {text-decoration:none !important;}
.subjectmessage {display: inline-block; min-width: 150px;}
.subjectmessage:hover{text-decoration:underline; cursor:pointer;}
.RSVPlistc svg { color: #03a9f4; font-size:18px; margin-right: 7px; }
.Dtaltab header button { min-width: 140px !important;}
.RSVPlistc table th { text-align: left; }
.insuranceTab button{min-width:70px !important;}
.linktitlesarch {color : #03a9f4; font-weight: 600; cursor: pointer; }
.notification_bar {  text-align: center; display: flex; width: 22px;  height: 22px;
  color: #fff; font-weight: 800; font-size: 12px; margin-left: 18px; border: 1px solid #db0202; background: #db0202;
  border-radius: 50%; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center; }
span.editevent_link_delete { cursor: pointer; color: #0aabf4; padding-left: 10px; }
.sharecontact {width: 100%;}
.msgNw_tabInner td label {  margin-bottom: 0px; }
.delete_div_msg {margin-bottom: 20px;}
.msgNw_tabInner svg { color: #2fb8f6; font-size: 20px; }
.event_link_move { cursor: pointer; color: #0aabf4 !important; text-decoration: underline !important;}
.pointer { cursor : pointer; }
.notAllow {cursor: not-allowed;}
.cstmTabMsg{ z-index: 9999; }
.socialShare { padding-top: 10px; }
/* .photogallery { display: contents !important; } */
/* Add by Ankita end */

/* Sponser page css */
.sponsor_terms>label {
  font-size: 18px;
  font-weight: 600;
  color: #246197;
  position: absolute;
  top: -17px;
  left: 20px;
  background: #ffffff;
  padding-left: 12px;
  padding-right: 12px;
  }
  .sponsor_terms {
  margin-top: 56px;
  border: 2px solid #0288d1;
  padding: 26px 33px;
  border-radius: 15px;
  position: relative;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 6px 7px 0px rgba(27, 95, 147, 0.44);
  margin-bottom: 55px;
  }
  .sponsor_terms p {
  margin-bottom: 0px;
  }
  .sponsorTermsAbc {
  font-weight: 500;
  cursor: pointer;
  color: #03a9f7;
  }
  .sponsorTermsMnth {
  font-weight: 500;
  color: black;
  }
  .sponsor_termsBtns button span {
  color: #ffffff;
  }
  .sponsor_termsBtns button {
  margin-right: 16px;
  cursor: pointer;
  }
  .sponsor_termsBtns {
  margin: 20px 0px 12px;
  }
  .sponsor_terms p a {
  color: red !important;
  font-weight: 600;
  margin-left: 8px;
  }
  .cardLogo a {
  margin: 0px 15px;
  }
  .cardLogoMain {
  margin-top: 45px;
  }
  .cardLogoLbl label {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
  color: #246197;
  }
  .cardLogo {
  margin-top: 20px;
  margin-bottom: 35px;
  }
  .cardFields .cardFieldsInpt {
  width: 96%;
  margin-bottom: 17px;
  margin-left: 2%;
  margin-right: 2%;
  }
  label.payAddress {
  font-size: 16px;
  color: #cc0002;
  font-weight: 500;
  margin-left: 1%;
  }
  .payAddressMain {
  margin-top: 15px;
  }
  label.approvOptLbl {
  display: block;
  color: #07a8f4;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  }
  .approvOpt {
  margin-top: 18px;
  }
  .approvOpt svg {
  color: green;
  }
  .communityName {
  margin-top: 20px;
  }
  .pendingAprov {
  color: #00d400;
  vertical-align: sub;
  }
  .sponserAprov {
  width: 22px;
  }
  .comSponser h1 {
  font-size: 23px;
  color: #03a9f4;
  font-weight: 500;
  margin-top: 55px;
  }
  /* End of Sponser page css */

  /* end of community manage success msg  */
    .cstmTabMsg{
      position: relative;
    }
    .cstmTabMsg .Successmsg {
      position: absolute;
      background: #00dc00e3;
      z-index: 9;
      margin: 0 auto;
      width:100%;
      /* left: 10%;
      right: 10%; */
      border-radius: 12px;
    }
    .cstmTabMsg .Successmsg h2{
      color: #ffffff;
      padding: 0px 0px;
      font-weight: 500;
      font-size: 18px;
    }
  /* community manage success msg  */
  .selectByType {
      margin-top: 12px;
   }
   .SetwidthTab.insuranceTab span {
    padding-left: 0px;
    padding-right: 0px;
    }
    .p_insurance_tab a {
      min-height: 20px;
    }

  /* stats tab css */
  .statsContnt {
      margin-top: 21px;
      border: 2px solid #0288d1;
      padding: 24px 33px;
      border-radius: 15px;
  }
  .statsContnt div:last-child b {
    margin-bottom: 0px;
  }
  .statsContnt b {
      font-weight: 500;
      min-width: 421px;
      display: inline-block;
      margin-bottom: 13px;
  }
  .distance_zip {
      margin-top: 0px !important;
  }
  .toRmvScroll div[data-swipeable="true"] {
    overflow: hidden !important;
 }
  .defaulupload img {
    height: auto;
    height: 60px;
    width: initial !important;
    border-radius: 5px;
  }
  a.attend_event_set {
    min-width: 110px;
    display: inline-block;
}





@media only screen
and (min-device-width : 320px)
and (max-device-width : 812px)
and (orientation : portrait) {
  .nomobile {
    display: none;
  }
  .col-2, .col-6, .col-10, .col-12 {
    margin: 0px;
    padding: 0px;
  }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 736px)
and (orientation : landscape) {
  .nomobile {
    display: none;
  }
}

@media only screen
and (min-device-width : 813px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .nomobile {
    display: none;
  }
}

@media only screen
and (min-device-width : 813px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  .nomobile {
    display: none;
  }
}

@media only screen
and (min-device-width : 1024px)
and (orientation : landscape) {
  .mobileOnly {
    display: none;
  }
}



.pr_autoComplete input {
  border-bottom: 1px solid #949494;
  width: 100%;
}
.pr_autoComplete .menu {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  border: none;
  background: #fff;
  z-index: 9;
  margin-top: 0;
  border-top: none;
  border-bottom: none;
  box-shadow: 0px 6px 12px rgb(0 0 0 / 15%);
}

.pr_autoComplete .item {
  padding: 5px 10px;
  cursor: default;
  font-size: 14px;
}

.pr_autoComplete .item-highlighted {
  color: black;
  background-color: #e8e8e8;
}

.pr_autoComplete .item-header {
  background-color: #eeeeee;
  color: #454545;
  font-weight: bold;
}

.create_listing_container_form .form-group {
  margin-bottom: 40px;
}
.pr_autoComplete .menu .item {
  border: 1px solid #ddd;
  margin-top: -1px;
}

.pr_autoComplete input::-webkit-input-placeholder { /* Edge */
  color: #949494;
}

.pr_autoComplete input:-ms-input-placeholder { /* Internet Explorer */
  color: #949494;
}

.pr_autoComplete input::placeholder {
  color: #949494;
}

span.verify_badge {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 30px;
  font-size: 13px;
}
.pr_autoComplete {
  position: relative;
}








/* SEN-180 Design css */
.team_listing_table_vs button.btn.btn-sm.mx-1 {
  margin: 2px 2px !important;
  width: 36px;
}
.search_detail_list_bio ul li {
  display: flex;
  align-items: flex-start;
}
.search_detail_list_bio ul li b {
  margin-right: 10px;
  min-width: 75px;
}
.search_detail_list_bio ul li p {
    margin: 0;
}
.search_detail_list_bio ul {
  margin: 0;
}
.sidebar {
  min-width: 1px;
}
.tab_panl_vs .MuiBox-root {
  padding: 24px 0 !important;
}
.view_by_chk li{
  margin-right: 25px;
}
.create_acc_grid{
  cursor: pointer;
  height: 100%;
  position: relative;
}
.create_acc_grid p{
  max-width: 280px;
    margin: 0 auto;
    line-height: 19px;
    font-size: 15px;
}
.create_acc_grid h5 {
  margin-bottom: 1rem;
  font-size: 15px;
}
.create_acc_grid h3 {
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 10px !important;
}
.create_acc_grid .card {
  height: 100%;
}
label + .MuiInput-formControl {
  margin-top: 18px !important;
}
.rc-menu-container {
  width: 100% !important;
}
.rc-menu-container ul {
  min-width: 100% !important;
}

@media (max-width: 1025px){
  .search_left_sidebar .nomobile {
    display: block;
  }
  .col-12.col-sm-4.search_left_sidebar {
    padding-right: 15px;
}
form.filtr_form_chk label {
  word-break: break-all;
  font-size: 14px;
}
form.filtr_form_chk .container {
  padding: 0;
}
.MuiTabs-fixed {
  overflow-x: scroll !important;
}
.tab_panl_vs .react-swipeable-view-container > div {
  overflow: hidden !important;
}
}

@media (max-width: 820px){
  .create_acc_section .col-12 {
    padding-left: 15px;
    padding-right: 15px;
}
.create_acc_grid h3 {
  font-size: 24px;
  line-height: 28px;
}
  .team_listing_table_vs button.btn.btn-sm.mx-1 {
    font-size: 12px;
    padding: 4px;
    width: 28px;
    margin: 3px 2px !important;
  }
  .team_listing_table_vs.table .thead-light th {
    font-size: 12px;
    line-height: 14px;
    vertical-align: middle;
  }
  .team_listing_table_vs.table td {
    font-size: 13px;
}
.search_detail_list_bio {
  padding: 0px 15px !important;
}
.sidebar-link, a.sidebar-link {
  padding: 0.7rem 0rem;
}
.MuiTabs-fixed {
  width: 100%;
  overflow-x: scroll !important;
}
.sidebar{
  border-right: none;
}
.sidebar-nav {
  padding-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.userBasics {
  padding: 25px 20px 25px;
}


}



@media (max-width: 767px) {
  .search_left_sidebar .nomobile {
    display: none;
  }
  .userBasics .form-group .col-sm-12 {
    padding: 0;
}
.create_acc_grid {
  height: auto;
  margin-bottom: 15px;
}
}


@media (min-width: 1px) and (max-width: 991.98px){
  .sidebar {
    margin-left: 0;
}
}


@media only screen and (min-device-width: 320px) and (max-device-width: 520px) and (orientation: portrait){
  .search_left_sidebar .nomobile {
    display: none;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (orientation: portrait){
  .eit_listing_content_vs .col-2, .eit_listing_content_vs .col-6, 
  .eit_listing_content_vs .col-10, .eit_listing_content_vs .col-12 {
    padding: 0px 15px !important;
}
}
